


































import {
  Vue,
  Component,
} from 'vue-property-decorator';

@Component({
  name: 'WithdrawalOperationInProcess',
})
export default class WithdrawalOperationInProcess extends Vue {
  i18n_namespace = 'components.kuspit-dashboard.withdrawals.operation_in_process';
}

